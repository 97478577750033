<template>
  <div class="page">
    <div v-if="loaded" class="back">
      <van-loading
        size="80px"
        text-size="24px"
        color="#fff"
        type="circular"
        vertical
        >加载中...</van-loading
      >
    </div>
    <div class="section-search">
      <div class="search-flex">
        <div class="flex-left">
          <!-- <img @click="back" src="./img/clear.png" alt="" /> -->
        </div>
        <div class="flex-right">
          <div class="right-input">
            <div class="input-wrap">
              <div class="input-current">
                <div class="circle"></div>
                <v-input
                  ref="curInput"
                  v-model="form.curStart"
                  :maxlength="20"
                  :disabled="true"
                  placeholder="请输入当前位置"
                  @focus="curFocus"
                  @blur="curBlur"
                  @change="curChange"
                />
              </div>
              <div class="line"></div>
              <div class="input-arrive">
                <div class="circle"></div>
                <v-input
                  ref="arriveInput"
                  v-model="form.arrowEnd"
                  :maxlength="20"
                  placeholder="请选择目标位置"
                  @focus="arriveFocus"
                  @blur="arriveBlur"
                  @change="arriveChange"
                />
              </div>
            </div>
            <div class="input-icon" v-show="isShowIcon" @click="toPlan">
              <img src="./img/go_icon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="search-options"
        :style="{ width: oWidth, maxHeight: oHeight }"
        v-if="isList"
      >
        <div
          class="search-options--option"
          v-for="(option, oIndex) in listData"
          :key="oIndex"
          @click="chooseOption(option)"
        >
          <div class="image"><img src="./img/search_icon.png" alt="" /></div>
          <span>{{ option.name }}</span>
        </div>
      </div>
    </div>
    <div class="body">
      <iframe
        id="planningIframe"
        class="bgIframe"
        :src="srcUrl"
        frameborder="0"
        width="100%"
        height="100%"
      ></iframe>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import { Loading } from "vant";
Vue.use(Loading);
import { mapState } from "vuex";
import { dataListByNameURL, bimRoadUrl } from "./api";
import { handleImg } from "@/utils/utils";
export default {
  data() {
    return {
      innerWidth: window.innerWidth,
      isList: false,
      srcUrl: "https://project.zhuneng.cn/maze/#/iframe",
      isOption: false, // 是点击选项
      isShowIcon: false,
      listData: [],
      focus: undefined,
      curTab: {},
      curForm: {
        start: undefined, // 开始
        curStart: "",
        end: undefined, // 前往
        arrowEnd: "",
      },
      form: {
        start: undefined, // 开始
        curStart: "",
        end: undefined, // 前往
        arrowEnd: "",
      },
      timer: null,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      curPage: 1,
      pageSize: 20,
      id: "",
      oHeight: "",
      current: {
        start: "",
        end: "",
        id: "50814f32fb884a82aa46f100e708be5f",
      },
      loaded: true,
    };
  },
  watch: {
    // form: {
    //   handler(val) {
    //     const { curStart, arrowEnd } = val;
    //     this.isShowIcon = curStart && arrowEnd;
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  computed: {
    handleImg(picture) {
      return handleImg(window.innerWidth, window.innerHeight, picture);
    },
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    window.addEventListener("message", this.changeLoaded);
    console.log(document.body.clientHeight, "-");
    this.oWidth = this.innerWidth - 30 * (this.innerWidth / 375) + "px";
    this.oHeight = window.innerHeight - 180 * (this.innerWidth / 375) + "px";
    let { form, focus, curTab, id, start } = this.$route.query;
    this.id = start || 45;
    if (form && focus && curTab) {
      this.form = JSON.parse(form);
      this.curForm = JSON.parse(form);
      this.curTab = JSON.parse(curTab);
      this.focus = focus;
    }
    this.$nextTick(() => {
      this.getInitData();
      this.isList = true;
      this.onNewRefresh();
      //   if (this.focus === "1") {
      //     this.$refs.curInput.focus();
      //   } else {
      //     this.$refs.arriveInput.focus();
      //   }
    });
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === "routePlanPlanning") {
        vm.$router.replace({
          name: "routePlan",
        });
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("message", this.changeLoaded);
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.setItem(
      "routePlanQuery",
      JSON.stringify({
        form: this.form,
        curTab: this.curTab,
      })
    );
    next();
  },

  methods: {
    async getInitData() {
      let res = await this.$axios.get(bimRoadUrl, {
        params: {
          type: 3,
          id: this.id,
        },
      });
      if (res.code == 200 && res.data && res.data.records) {
        this.form.curStart = res.data.records[0].name;
        this.current.start = res.data.records[0].id;
      }
    },
    back() {
      // this.$router.go(-2);
      this.isOption = true;
      this.form = {
        start: undefined, // 开始
        curStart: "",
        end: undefined, // 前往
        arrowEnd: "",
      };
      // this.$router.go(-1);
      this.$router.replace({
        name: "routePlan",
      });
    },
    // 选择地上地下
    // chooseTab(item) {
    //   this.curTab = item;
    // },
    //防抖
    debounce(fn, wait) {
      if (this.timer !== null) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(fn, wait);
    },
    // 当前位置
    curFocus() {
      this.isList = true;
      this.focus = "1";
      this.timer = null;
      this.onRefresh();
    },
    curBlur() {
      // const timer = setTimeout(() => {
      //   // this.form.arrowEnd = this.curForm.curStart;
      //   const data = this.listData.filter((v) => v.name === this.form.curStart);
      //   if (data.length > 0) {
      //     this.form.start = data[0].id;
      //   } else {
      //     this.form.start = undefined;
      //   }
      //   clearTimeout(timer);
      // }, 100);
    },
    // 当前位置-数据变化
    curChange() {
      this.debounce(this.onRefresh, 500);
    },
    arriveFocus() {
      //   this.focus = "2";
      //   this.timer = null;
      this.isList = true;
      this.onNewRefresh();
    },
    async onNewRefresh() {
      let res = await this.$axios.get(bimRoadUrl, {
        params: {
          type: 2,
        },
      });
      if (res.code == 200 && res.data && res.data.records) {
        this.listData = res.data.records;
      }
    },
    arriveBlur() {
      //   this.isList = false;
    },
    arriveChange() {
      //   this.debounce(this.onRefresh, 500);
    },
    changeLoaded(ev) {
      // 监听iframe资源是否加载完成，可以发送postmessage
      console.log(ev, "99");
      if (ev.data && ev.data.type === "loaded") {
        this.loaded = false;
        //进行操作
      }
    },
    // 选择位置选项
    chooseOption(item) {
      console.log(88, "9999", item);
      this.isList = false;
      this.form.arrowEnd = item.name;
      this.current.end = item.id;
      let iframe = document.getElementById("planningIframe").contentWindow;
      if (iframe && this.current.end) {
        this.$toast.loading({
          duration: 0,
          message: "加载中...",
          forbidClick: true,
          loadingType: "spinner",
        });
        const _this = this;
        const timer = setInterval(() => {
          this.$toast.clear();
          this.loaded = false;
          this.showIframe();
          clearTimeout(timer);
        }, 300);
      }
      //   if (this.focus === "1") {
      //     this.form.start = item.id;
      //     this.form.curStart = item.name;
      //     this.curForm.start = item.id;
      //     this.curForm.curStart = item.name;
      //   } else {
      //     this.form.end = item.id;
      //     this.form.arrowEnd = item.name;
      //     this.curForm.end = item.id;
      //     this.curForm.arrowEnd = item.name;
      //   }
      //   // this.$router.go(-1);
      //   this.$router.replace({
      //     name: "routePlan",
      //   });
    },
    showIframe() {
      let iframe = document.getElementById("planningIframe").contentWindow;
      console.log(this.current, "00");
      iframe.postMessage(
        // Object,start和end为POI的id，id为导航BIM的资源id
        { type: "maze", data: this.current },
        "*"
      );
    },
    vaild() {
      if (!this.form.start) {
        this.$toast({ message: "请输入正确的当前位置", duration: 1500 });
        return;
      } else if (!this.form.end) {
        this.$toast({ message: "请输入正确的到达位置", duration: 1500 });
        return;
      } else if (this.form.start === this.form.end) {
        this.$toast({ message: "起点等于终点，无需导航", duration: 1500 });
        return;
      }
      return true;
    },
    // 前往路线规划页面
    toPlan() {
      let result = this.vaild();
      if (result) {
        this.$router.push({
          name: "routePlanPlanning",
          query: {
            form: JSON.stringify(this.form),
            curTab: JSON.stringify(this.curTab),
            focus: this.focus,
          },
        });
      }
    },

    // 获取用户信息
    // getUserInfo() {
    //   if (!this.userId || this.userId == "0") {
    //     this.onLoad();
    //     return;
    //   }
    //   let params = {
    //     userId: this.userId,
    //   };
    //   this.$axios
    //     .get(userInfoURL, {
    //       params,
    //     })
    //     .then((res) => {
    //       if (res.code === 200) {
    //         this.userInfo = res.data;
    //         this.phone = this.userInfo.mobile;
    //       }
    //     })
    //     .finally(() => {
    //       this.onLoad();
    //     });
    // },

    onRefresh() {
      //   if (this.isOption) {
      //     this.isOption = false;
      //     return;
      //   }
      //   /* 请求接口前清空当前、到达的id */
      //   if (this.focus === "1") {
      //     this.form.start = undefined;
      //   } else {
      //     this.form.end = undefined;
      //   }
      //   /* end */
      //   this.finished = true;
      //   this.curPage = 1;
      //   this.listData = [];
      //   this.onLoad();
    },
    // 加载列表数据
    onLoad() {},
  },
};
</script>
<style lang="less" scoped>
.page {
  height: 100vh;
  .back {
    left: 0;
    top: 0;
    z-index: 9999;
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("./img/back.png") center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  width: 100%;
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  background: url("./img/background.png") no-repeat left top;
  background-size: 100% 484px;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .section-search {
    box-sizing: border-box;
    z-index: 9998;
    width: 100%;
    //  height: 484px;
    padding: 80px 30px 64px;
    position: absolute;
    .search-flex {
      display: flex;
      width: 100%;
      // height: 100%;
      box-sizing: border-box;
      padding: 30px;
      background: #ffffff;
      border-radius: 16px;
      box-shadow: 0px 4px 22px 0px rgba(229, 229, 229, 0.5);
      .flex-left {
        margin-right: 48px;
        img {
          // height: 26px;
          // width: 14px;
          height: 24px;
          width: 24px;
        }
      }
      .flex-right {
        flex: 1;
        .right-input {
          display: flex;
          align-items: center;
          background: #f9f9f9;
          width: 100%;
          height: 160px;
          border-radius: 16px;
          //  font-size: 28px;
          .input-wrap {
            display: flex;
            flex-direction: column;
            height: 100%;
            .input-current,
            .input-arrive {
              flex: 1;
              display: flex;
              align-items: center;
              margin-right: 64px;
              .circle {
                margin: 0 12px 0 28px;
                display: flex;
                justify-content: center;
                align-items: center;
                &::before {
                  content: "";
                  width: 12px;
                  height: 12px;
                  background: #fff;
                  border-radius: 50%;
                  border: 6px solid #68cfa8;
                }
              }
              ::v-deep .van-cell {
                width: 356px;
                background-color: transparent;
                line-height: 40px;
                padding: 0;
                font-size: 28px;
                color: rgba(0, 0, 0, 0.85);
              }
            }
            .line {
              margin: 0 18px 0 50px;
              width: 420px;
              height: 2px;
              background: #f1f1f1;
            }
            .input-arrive {
              .circle {
                &::before {
                  border: 6px solid #fa6400;
                }
              }
            }
          }
          .input-icon {
            img {
              width: 64px;
              height: 64px;
              vertical-align: middle;
            }
          }
        }
        .right-tabs {
          display: flex;
          margin-top: 22px;
          font-size: 26px;
          font-weight: 500;
          .right-tabs--tab {
            border-radius: 10px;
            padding: 8px 14px;
            margin-right: 36px;
            color: rgba(0, 0, 0, 0.5);
            &.selectTab {
              background: linear-gradient(180deg, #549afe, #247dff);
              color: #ffffff;
            }
          }
        }
      }
    }
    .search-options {
      position: absolute;
      overflow-y: auto;
      z-index: 999;
      box-sizing: border-box;
      margin-top: 20px;
      background: #ffffff;
      box-shadow: 0px 4px 22px 0px rgba(229, 229, 229, 0.5);
      padding: 0px 22px 22px;
      font-size: 28px;
      color: rgba(0, 0, 0, 0.85);
      .search-options--option {
        display: flex;
        padding: 30px 12px;
        border-bottom: 2px solid #eeeeee;
        line-height: 40px;
        .image {
          padding-top: 4px;
          img {
            width: 28px;
            height: 28x;
            margin: 0 18px 0 0;
          }
        }
        span {
          word-wrap: break-word;
          word-break: break-all;
        }
      }
    }
  }
  .body {
    flex: 1;
    .bgIframe {
      border: 0;
      vertical-align: top;
    }
  }
}
</style>
